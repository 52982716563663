import { LoginCheckerPropsWithChildren, NextIronRequest } from '@aims-auth/types'
import React, { useEffect } from 'react'
import {
  getApiConfiguration,
  getSearchFilterFields,
  getUser,
  isLoggedOut,
  isPublic as isPublicSelector,
  loginSuccess,
  setApiConfiguration,
  setSearchFilterFields
} from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { CircularProgress } from '@material-ui/core'
import { NextApiResponse } from 'next'
import { PaginatedCollections } from '@aims-collection/types'
import { SnackbarContainer } from '@aims-layout'
import { StyledCenter } from '@aims-auth/styled'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { getEnvConfig } from '@aims-lib'
import { getIronSessionOptions } from '@aims-auth/lib'
import { loadProjects } from '@aims-project/lib'
import { setProjects } from '@aims-store/project'
import { useRouter } from 'next/router'
import { withIronSession } from 'next-iron-session'

const LoginChecker = ({ children, session, apiConfig, autoTaggingConfig }: LoginCheckerPropsWithChildren): JSX.Element => {
  const router = useRouter()
  const user = useSelector(getUser)
  const apiConfiguration = useSelector(getApiConfiguration)
  const loggedOut = useSelector(isLoggedOut)
  const isPublic = useSelector(isPublicSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (session === null || loggedOut) {
      void router.push('/auth/login') // eslint-disable-line no-void
      return
    }
    if (user === null) {
      void refreshSession() // eslint-disable-line no-void
    }
    if (apiConfiguration === null) {
      dispatch(setApiConfiguration(apiConfig, autoTaggingConfig))
    }
    if (isPublic && router.pathname !== '/') {
      void router.push('/') // eslint-disable-line no-void
    }
  })

  const refreshSession = async (): Promise<void> => {
    const filterFields = await getSearchFilterFields(session)
    dispatch(loginSuccess(session))
    dispatch(setSearchFilterFields(filterFields))

    if (session.projects) {
      const results = await loadProjects(session)
      const projects = convertKeysToCamelCase<PaginatedCollections>(results)

      dispatch(setProjects(projects))
    }
  }

  return (user !== null && (!isPublic || router.pathname === '/'))
    ? <>
      {children}
      <SnackbarContainer />
    </>
    : <StyledCenter><CircularProgress /></StyledCenter>
}

// eslint-disable-next-line
export function getServerSideProps (): (...args: any[]) => Promise<any> {

  const apiConfig = getEnvConfig('demo-api')
  const autoTaggingConfig = getEnvConfig('auto-tagging')

  return withIronSession(
    async ({ req, res }: {req: NextIronRequest, res: NextApiResponse}) => {
      const user = req.session.get('user')

      if (typeof user === 'undefined' || user === null) {
        return {
          redirect: {
            permanent: false,
            destination: '/auth/login'
          }
        }
      }

      return {
        props: {
          session: user,
          apiConfig,
          autoTaggingConfig
        }
      }
    },
    getIronSessionOptions()
  )
}

export default LoginChecker
