import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledLoginForm = styled.form`
  margin-top: 1rem;
  ${media(ScreenSize.Small)} {
    margin-top: 2rem;
    width: 26rem;
  }
`
export default StyledLoginForm
