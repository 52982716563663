import { ScreenSize, media } from '@aims-theme'

import styled from 'styled-components'

const StyledLoginPage = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  
  ${media(ScreenSize.Small)} {
    margin-bottom: 4rem;
    padding: 0;
  }
  .loader {
    circle {
      stroke: ${props => props.theme.colors.secondary};
    }
  }

  .login-meta-actions {
    display: flex;
    justify-content: space-between;
  }

  .back-button {
    margin-bottom: 2rem;
  }

  .success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    gap: 1rem;
    margin-bottom: 1rem;

    & .icon {
      width: 4rem;
      height: 4rem;
      color: ${props => props.theme.colors.primary};
    }
  }
`
export default StyledLoginPage
