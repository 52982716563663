import { LoaderButtonTextual, TextField } from '@aims-controls'
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react'
import {
  StyledActionArea,
  StyledBackLink,
  StyledInfoText,
  StyledLoginForm,
  StyledLoginPage
} from '../../styled'
import { getUser, isLoading, requestReset, wasResetRequested } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import Link from 'next/link'
import Router from 'next/router'
import { SnackbarContainer } from '@aims-layout'

const ForgottenPasswordForm = (): JSX.Element => {
  const loading = useSelector(isLoading)
  const user = useSelector(getUser)
  const resetRequested = useSelector(wasResetRequested)
  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)
  const [email, setEmail]: [string, Dispatch<SetStateAction<string>>] = useState('')

  useEffect(() => {
    if (user !== null) {
      void Router.push('/') // eslint-disable-line no-void
    }
  }, [user])

  useEffect(() => {
    if (resetRequested) {
      setSuccess(true)
    }
  }, [resetRequested])

  const handleOnSubmit = (event: FormEvent): void => {
    event.preventDefault()
    if (!loading && !resetRequested) {
      dispatch(requestReset(email))
    }
  }

  return (
    <StyledLoginPage>
      <StyledLoginForm onSubmit={handleOnSubmit}>
        <StyledBackLink>
          <Link href={'/auth/login'}>Back to login</Link>
        </StyledBackLink>
        {success ? (
          <StyledInfoText>Please check your inbox to verify your e-mail and reset your password.</StyledInfoText>
        ) : (
          <>
            <StyledInfoText>
              Enter your account e-mail and we&apos;ll send you a link to reset the password
            </StyledInfoText>
            <TextField
              variant={'outlined'}
              color={'secondary'}
              margin={'normal'}
              required
              fullWidth
              id={'email'}
              label={'Email Address'}
              name={'email'}
              autoFocus
              value={email}
              disabled={loading || user !== null}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => setEmail(event.target.value)}
            />
            <StyledActionArea>
              <LoaderButtonTextual
                color={'secondary'}
                loading={loading}
                success={Boolean(user)}
                disabled={loading}
                type={'submit'}
                text={{ default: 'Submit', loading: 'Validating...', success: 'Check your inbox' }}
              />
            </StyledActionArea>
          </>
        )}
      </StyledLoginForm>
      <SnackbarContainer />
    </StyledLoginPage>
  )
}

export default ForgottenPasswordForm
