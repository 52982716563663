import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Router from 'next/router'
import { StyledLoginPage } from '@aims-auth/styled'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'
import { useResetPasswordForm } from '@aims-auth/hooks'

const ResetPasswordForm = (): JSX.Element => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const form = useResetPasswordForm()

  useEffect(() => {
    if (user !== null) {
      void Router.push('/') // eslint-disable-line no-void
    }
  }, [user])

  const navigateToLogin = (): JSX.Element => {
    dispatch(enqueue({ message: 'Reset successful! You can log in now.', options: { variant: 'success' } }))
    void Router.push('/auth/login') // eslint-disable-line no-void
    return <></>
  }

  return (
    <StyledLoginPage>
      {form.inProgress ? form.render() : navigateToLogin()}
    </StyledLoginPage>
  )
}

export default ResetPasswordForm
