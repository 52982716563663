import { InfoMessage, LoaderButtonTextual, TextField } from '@aims-controls'
import React, { FormEvent, ReactNode, useRef, useState } from 'react'
import { StyledActionArea, StyledBackLink, StyledLoginForm } from '@aims-auth/styled'
import { getUser, isLoading, setIsLoading } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { FormHook } from '@aims-auth/types'
import Link from 'next/link'
import ReCAPTCHA from 'react-google-recaptcha'
import { registerNewUser } from '@aims-auth/api'

enum RegistrationError {
  Forbidden = 'forbidden',
  Duplicate = 'duplicate',
  Throttle = 'throttle',
  FailedCaptcha = 'failed-captcha'
}

const DuplicateEmailErrorMessage = (): JSX.Element => {
  return (
    <>
      Sorry, this email is already registered. Try to <Link href={'/auth/login'}>log in</Link>.
    </>
  )
}

enum ErrorType {
  InvalidEmail = 0,
  InvalidEmailDomain = 1,
  DuplicateEmail = 2,
  Password = 3,
  PasswordMatch = 4
}

type ErrorMessages = {
  [key in ErrorType]: string | ReactNode
}

const errorMessages: ErrorMessages = {
  [ErrorType.InvalidEmail]: 'Please enter a valid email address.',
  [ErrorType.InvalidEmailDomain]: `Your email domain is not associated with any existing accounts.
            Please use your company address or contact our support.`,
  [ErrorType.DuplicateEmail]: <DuplicateEmailErrorMessage />,
  [ErrorType.Password]: `Password must be at least 8 characters long and
          must contain at least one number and one uppercase and lowercase character.`,
  [ErrorType.PasswordMatch]: 'Passwords do not match.'
}

interface Error {
  genericError: boolean
  emailError: ErrorType | null
}

type Errors = {
  [key in RegistrationError]: Error
}

const errors: Errors = {
  [RegistrationError.Forbidden]: {
    genericError: false,
    emailError: ErrorType.InvalidEmailDomain
  },
  [RegistrationError.Duplicate]: {
    genericError: false,
    emailError: ErrorType.DuplicateEmail
  },
  [RegistrationError.Throttle]: {
    genericError: true,
    emailError: null
  },
  [RegistrationError.FailedCaptcha]: {
    genericError: true,
    emailError: null
  }
}

const useRegistrationForm = (): FormHook => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [failedCaptcha, setFailedCaptcha] = useState(false)
  const [inProgress, setInProgress] = useState(true)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [genericError, setGenericError] = useState(false)
  const [passwordError, setPasswordError] = useState<ErrorType | null>(null)
  const [emailError, setEmailError] = useState<ErrorType | null>(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState<ErrorType | null>(null)

  const handleOnSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    if (recaptchaRef.current === null) {
      throw new Error('reCaptcha failed')
    }

    recaptchaRef.current.execute()
  }

  const handleEmail = ({
    target: { value }
  }: {
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  }): void => {
    const trimmedEmail = value.trim()
    setEmail(trimmedEmail)
    validateEmail(trimmedEmail)
  }

  const validateEmail = (emailValue: string): void => {
    const emailRegex = new RegExp(/^[0-9A-z.+-]+@[0-9A-z]+\.[A-z]+$/g)
    const emailIsInvalid = !emailRegex.test(emailValue)
    if (emailIsInvalid) {
      setEmailError(ErrorType.InvalidEmail)
    } else {
      setEmailError(null)
    }
    setGenericError(false)
  }

  const handlePassword = ({
    target: { value }
  }: {
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  }): void => {
    const newPasswordValue = value.replace(' ', '')
    setPassword(newPasswordValue)
    validatePassword(newPasswordValue)
    validateMatchPassword(newPasswordValue, confirmPassword)
  }

  const validatePassword = (newPassword: string): void => {
    const uppercase = new RegExp(/[A-Z]+/g)
    const lowercase = new RegExp(/[a-z]+/g)
    const digit = new RegExp(/[0-9]+/g)
    const missingCharCases = !uppercase.test(newPassword) || !lowercase.test(newPassword) || !digit.test(newPassword)
    const invalidLength = newPassword.length < 8
    const invalidPassword = invalidLength || missingCharCases
    if (invalidPassword) {
      setPasswordError(ErrorType.Password)
    } else {
      setPasswordError(null)
    }
  }

  const handleConfirmPassword = ({
    target: { value }
  }: {
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  }): void => {
    const newPasswordValue = value.replace(' ', '')
    setConfirmPassword(newPasswordValue)
    validateMatchPassword(newPasswordValue, password)
  }

  const validateMatchPassword = (newPassword: string, oldPassword: string): void => {
    if (newPassword !== oldPassword) {
      setConfirmPasswordError(ErrorType.PasswordMatch)
    } else {
      setConfirmPasswordError(null)
    }
  }

  const getHelperText = (error: ErrorType | null): string | ReactNode => {
    if (error === null) {
      return ''
    }
    return errorMessages[error]
  }

  const onReCAPTCHAChange = async (captchaCode: string|null|undefined): Promise<void> => {
    const codeIsExpired = captchaCode === null || captchaCode === undefined

    if (codeIsExpired) {
      return
    }

    try {
      if (!loading && user === null) {
        dispatch(setIsLoading(true))
        setFailedCaptcha(false)

        await registerNewUser(email, password, captchaCode)
          .then((): void => setInProgress(false))
          .catch((error: { status: RegistrationError }): void => {
            if (error.status === RegistrationError.FailedCaptcha) {
              setFailedCaptcha(true)
              setGenericError(true)
            }

            const currentError: Error | undefined = errors[error.status]
            if (currentError !== undefined) {
              setGenericError(currentError.genericError)
              setEmailError(currentError.emailError)
            }
          })

        if (recaptchaRef.current !== null) {
          recaptchaRef.current.reset()
        }
      }
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const render = (): JSX.Element => {
    return (
      <StyledLoginForm onSubmit={handleOnSubmit}>
        <StyledBackLink className={'back-button'}>
          <Link href={'/auth/login'}>Back to login</Link>
        </StyledBackLink>
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          id={'email'}
          label={'Email Address'}
          name={'email'}
          autoFocus
          value={email}
          error={emailError !== null}
          onBlur={handleEmail}
          helperText={getHelperText(emailError)}
          disabled={loading || user !== null}
          onChange={handleEmail}
        />
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          name={'password'}
          label={'Create Password'}
          type={'password'}
          id={'password'}
          autoComplete={'current-password'}
          value={password}
          error={passwordError !== null}
          helperText={getHelperText(passwordError)}
          onBlur={handlePassword}
          disabled={loading || user !== null}
          onChange={handlePassword}
        />
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          name={'password'}
          label={'Confirm password'}
          type={'password'}
          id={'confirm-password'}
          autoComplete={'current-password'}
          error={confirmPasswordError !== null}
          helperText={getHelperText(confirmPasswordError)}
          onBlur={handleConfirmPassword}
          value={confirmPassword}
          disabled={loading || user !== null}
          onChange={handleConfirmPassword}
        />
        {genericError && (
          <InfoMessage type={'warning'}>
            {failedCaptcha ? 'Captcha validation failed.' : 'It looks like something went wrong. Please try again later or contact us if unable to proceed.'}
          </InfoMessage>
        )}
        <StyledActionArea>
          <ReCAPTCHA
            ref={recaptchaRef}
            size={'invisible'}
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
            onChange={onReCAPTCHAChange}
          />
          <LoaderButtonTextual
            color={'secondary'}
            loading={loading}
            success={user !== null}
            disabled={loading || emailError !== null || passwordError !== null || confirmPasswordError !== null}
            type={'submit'}
            text={{ default: 'Register', loading: 'Register', success: 'Registered' }}
          />
        </StyledActionArea>
      </StyledLoginForm>
    )
  }

  return {
    render,
    inProgress
  }
}

export default useRegistrationForm
