import styled from 'styled-components'

const StyledCenter = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
  & > div {
    height: 5rem;
    width: 5rem;
    circle {
      stroke: ${props => props.theme.colors.primary};
    }
  }
`
export default StyledCenter
