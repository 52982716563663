import { Cancel, CheckCircle } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { PresentationTextField } from '@aims-controls'
import RegistrationConfirmationPageStyled from './RegistrationConfirmationPage.styled'
import { Typography } from '@material-ui/core'
import { confirmEmail } from '@aims-auth/api'
import { useRouter } from 'next/router'

const pageTemplate = {
  valid: {
    title: <Typography variant='h6' gutterBottom>Your account was successfully activated.</Typography>,
    body: <Typography align={'center'}>If you want to automatically log in without your email and password,<br></br>
      you can use this private magic login link. Do not share it with anyone!</Typography>,
    Icon: <CheckCircle className={'status-icon'}></CheckCircle>
  },
  invalid: {
    title: <Typography variant='h6' gutterBottom>Sorry, this confirmation link is not valid or has already expired.</Typography>,
    body: <Typography align={'center'}>Make sure you copied the link from your inbox correctly or try signing up again.<br></br>
    If you astill have issues, please contact us at <a href={'mailto: info@aimsapi.com'}>info@aimsapi.com</a></Typography>,
    Icon: <Cancel className={'status-icon status-icon--failed'}></Cancel>
  }
}

const RegistrationConfirmationPage = (): JSX.Element => {
  const router = useRouter()
  const [loading, setLoading] = useState(true)
  const [invalidToken, setInvalidToken] = useState(false)
  const [magicLoginLink, setMagicLoginLink] = useState('')

  useEffect(() => {
    confirmEmail(router.query.token as string)
      .then((response): void => {
        setInvalidToken(false)
        setLoading(false)
        setMagicLoginLink(response.link)
      })
      .catch((): void => {
        setInvalidToken(true)
        setLoading(false)
      })
  }, [router, setInvalidToken, setLoading])

  if (loading) {
    return <></>
  }

  const template = invalidToken ? pageTemplate.invalid : pageTemplate.valid

  return (
    <RegistrationConfirmationPageStyled>
      {template.Icon}
      {template.title}
      {template.body}
      {!invalidToken && (
        <PresentationTextField
          variant={'outlined'}
          value={magicLoginLink}
          label={'Magic login link'}
          inputProps={{
            readOnly: true
          }}
          fullWidth
          focused
        />
      )}
    </RegistrationConfirmationPageStyled>
  )
}

export default RegistrationConfirmationPage
