import { Link, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { StyledBackLink, StyledLoginPage } from '@aims-auth/styled'

import { Email } from '@material-ui/icons'
import Router from 'next/router'
import { SnackbarContainer } from '@aims-layout'
import { getUser } from '@aims-store/auth'
import { useRegistrationForm } from '@aims-auth/hooks'
import { useSelector } from 'react-redux'

const RegistrationPage = (): JSX.Element => {
  const user = useSelector(getUser)
  const form = useRegistrationForm()

  useEffect(() => {
    if (user !== null) {
      void Router.push('/') // eslint-disable-line no-void
    }
  }, [user])

  return (
    <StyledLoginPage>
      {form.inProgress ? form.render()
        : (
          <>
            <Typography className={'success-page'}>
              <Email className={'icon'}/>
              Thank you!
              Check your inbox and click the link to confirm your email.
            </Typography>
            <StyledBackLink className={'back-button'}>
              <Link href={'/auth/login'}>Back to login</Link>
            </StyledBackLink>
          </>
        )
      }
      <SnackbarContainer />
    </StyledLoginPage>
  )
}

export default RegistrationPage
