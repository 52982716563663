import { InfoMessage, LoaderButtonTextual, TextField } from '@aims-controls'
import React, { ChangeEvent, FocusEvent, FormEvent, useState } from 'react'
import { StyledActionArea, StyledLoginForm } from '@aims-auth/styled'
import { getUser, isLoading, setIsLoading } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { FormHook } from '@aims-auth/types'
import { resetPasswordNext } from '@aims-auth/api'
import { useRouter } from 'next/router'

const useResetPasswordForm = (): FormHook => {
  const user = useSelector(getUser)
  const loading = useSelector(isLoading)
  const router = useRouter()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [generalError, setGeneralError] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordMatchError, setPasswordMatchError] = useState(false)
  const [inProgress, setInProgress] = useState(true)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  const handleOnSubmit = (event: FormEvent): void => {
    event.preventDefault()
    if (!loading && user === null) {
      dispatch(setIsLoading(true))
      setSubmitButtonDisabled(true)
      const token = router.query.token as string

      const resetPasswordAsync = async (): Promise<void> => {
        await resetPasswordNext(password, token)
          .then(() => {
            setGeneralError('')
            setInProgress(false)
          })
          .catch((err) => {
            setGeneralError((err as Error).message)
          })

        dispatch(setIsLoading(false))
        setSubmitButtonDisabled(false)
      }

      void resetPasswordAsync() // eslint-disable-line no-void
    }
  }

  const getErrorMessage = (type: 'password'|'confirm-password'): string => {
    switch (type) {
      case 'password': {
        if (passwordError) {
          return `Password must be at least 8 characters long and
          must contain at least one number and one uppercase and lowercase character.`
        }
        return ''
      }
      case 'confirm-password': {
        if (passwordMatchError) {
          return 'Passwords do not match.'
        }
        return ''
      }
      default: {
        return ''
      }
    }
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newPasswordValue = event.target.value.replace(' ', '')
    setPassword(newPasswordValue)
    const passwordsDontMatch = newPasswordValue !== confirmPassword
    const isPasswordValid = getIsPasswordInvalid(newPasswordValue)
    setPasswordMatchError(passwordsDontMatch)
    updateSubmitButtonState(isPasswordValid, passwordsDontMatch)
  }

  const handlePasswordBlur = (event: FocusEvent<HTMLInputElement, Element>): void => {
    const newPasswordValue = event.target.value.replace(' ', '')
    const passwordsDontMatch = newPasswordValue !== confirmPassword
    setPassword(newPasswordValue)
    setPasswordMatchError(passwordsDontMatch)
    const isPasswordInvalid = getIsPasswordInvalid(newPasswordValue)
    updateSubmitButtonState(isPasswordInvalid, passwordsDontMatch)
  }

  const getIsPasswordInvalid = (newPassword: string): boolean => {
    const uppercase = new RegExp(/[A-Z]+/g)
    const lowercase = new RegExp(/[a-z]+/g)
    const digit = new RegExp(/[0-9]+/g)
    const missingCharCases = !uppercase.test(newPassword) || !lowercase.test(newPassword) || !digit.test(newPassword)
    const invalidLength = newPassword.length < 8
    const invalidPassword = invalidLength || missingCharCases

    setPasswordError(invalidPassword)
    return invalidPassword
  }

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newPasswordValue = event.target.value.replace(' ', '')
    const passwordsDontMatch = newPasswordValue !== password
    setConfirmPassword(newPasswordValue)
    setPasswordMatchError(passwordsDontMatch)
    updateSubmitButtonState(passwordError, passwordsDontMatch)
  }

  const handleConfirmPasswordBlur = (event: FocusEvent<HTMLInputElement, Element>): void => {
    const newPasswordValue = event.target.value.replace(' ', '')
    const passwordsDontMatch = newPasswordValue !== password
    setPasswordMatchError(passwordsDontMatch)
    updateSubmitButtonState(passwordError, passwordsDontMatch)
  }

  const updateSubmitButtonState = (passwordError: boolean, passwordMatchError: boolean): void => {
    const noErrors = !passwordError && !passwordMatchError
    setSubmitButtonDisabled(!noErrors || loading)
  }

  const render = (): JSX.Element => {
    return (
      <StyledLoginForm onSubmit={handleOnSubmit}>
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          name={'password'}
          label={'New Password'}
          type={'password'}
          id={'password'}
          autoFocus
          error={passwordError}
          helperText={getErrorMessage('password')}
          value={password}
          disabled={loading}
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
        />
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          name={'confirmPassword'}
          label={'Confirm Password'}
          type={'password'}
          id={'confirmPassword'}
          error={passwordMatchError}
          helperText={getErrorMessage('confirm-password')}
          value={confirmPassword}
          disabled={loading}
          onChange={handleConfirmPasswordChange}
          onBlur={handleConfirmPasswordBlur}
        />
        {generalError !== '' && <InfoMessage type={'warning'}>{generalError}</InfoMessage>}
        <StyledActionArea>
          <LoaderButtonTextual
            color={'secondary'}
            loading={loading}
            success={Boolean(user)}
            disabled={submitButtonDisabled}
            type={'submit'}
            text={{ default: 'Reset', loading: 'Resetting...', success: 'Reset completed' }}
          />
        </StyledActionArea>
      </StyledLoginForm>
    )
  }

  return {
    render,
    inProgress
  }
}

export default useResetPasswordForm
