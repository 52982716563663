import styled from 'styled-components'

const StyledBackLink = styled.div`
  a {
    font-size: 1.2rem;

    &::before {
      border: 0.6rem solid transparent;
      border-right-color: ${props => props.theme.colors.secondary};
      box-sizing: border-box;
      content: '';
      display: inline-block;
      height: 1.2rem;
      margin-bottom: -0.2rem;
      margin-left: -0.5rem;
      margin-right: 0.8rem;
      width: 1.2rem;
    }
  }
`

export default StyledBackLink
