import { ScreenSize, media } from '../../../../styled/mixins'

import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledInfoText = styled(Typography)`&& {
  color: ${props => props.theme.colors.grey.medium};
  font-size: 0.75rem;
  font-weight: ${props => props.theme.font.medium};
  padding-top: 2rem;
  text-transform: uppercase;
  ${media(ScreenSize.Small)} {
    font-size: 1.1rem;
  }
}`

export default StyledInfoText
