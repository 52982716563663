import { CancelOutlined, CheckOutlined } from '@material-ui/icons'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { getUser, hasError, isLoading, magicLogin } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { SnackbarContainer } from '@aims-layout'
import { StyledLoginPage } from '@aims-auth/styled'

const MagicLogin = (): JSX.Element => {
  const router = useRouter()
  const loading = useSelector(isLoading)
  const error = useSelector(hasError)
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user !== null) {
      void Router.push('/') // eslint-disable-line no-void
    }
  }, [user])

  useEffect(() => {
    const token = router.query.token
    if (!error && !loading && user === null && typeof token !== 'undefined') {
      dispatch(magicLogin(token as string))
    }
  })

  return (
    <StyledLoginPage>
      {
        user !== null
          ? <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
            <CheckOutlined/>
            <Typography variant={'h5'}>
              Logged in
            </Typography>
          </Grid>
          : ((loading || !error)
            ? <CircularProgress className={'loader'} />
            : <Grid container justifyContent={'center'} alignItems={'center'}>
              <CancelOutlined color={'secondary'} fontSize={'large'} />
              <Typography variant={'h5'}>
                &nbsp;Invalid link. Please check that the link you pasted is not partial.
              </Typography>
            </Grid>
          )
      }
      <SnackbarContainer />
    </StyledLoginPage>
  )
}

export default MagicLogin
