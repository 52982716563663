import { ScreenSize, media } from '@aims-theme'

import styled from 'styled-components'

const RegistrationConfirmationPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;

  .status-icon {
    color: ${props => props.theme.colors.green};
    width: 2rem;
    height: 2rem;

    ${media(ScreenSize.Small)} {
      width: 4rem;
      height: 4rem;
    }

    &--failed {
      color: ${props => props.theme.colors.crimson};
    }
  }

  .magic-login-link {
    width: calc(100% - 2rem);
    background-color: ${props => props.theme.colors.grey.lightest};

    ${media(ScreenSize.Small)} {
      max-width: 30rem;
    }

    &__input {
      height: 1.5rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
    }
  }
`

export default RegistrationConfirmationPage
