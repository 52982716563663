import { LoaderButtonTextual, TextField } from '@aims-controls'
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react'
import { StyledActionArea, StyledLoginForm, StyledLoginPage } from '@aims-auth/styled'
import { getUser, isLoading, login } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import Link from 'next/link'
import Router from 'next/router'
import { SnackbarContainer } from '@aims-layout'

const LoginForm = (): JSX.Element => {
  const loading = useSelector(isLoading)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [email, setEmail]: [string, Dispatch<SetStateAction<string>>] = useState('')
  const [password, setPassword]: [string, Dispatch<SetStateAction<string>>] = useState('')

  useEffect(() => {
    if (user !== null) {
      void Router.push('/') // eslint-disable-line no-void
    }
  }, [user])

  const handleOnSubmit = (event: FormEvent): void => {
    event.preventDefault()
    if (!loading && user === null) {
      dispatch(login(email, password))
    }
  }

  return (
    <StyledLoginPage>
      <StyledLoginForm onSubmit={handleOnSubmit}>
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          id={'email'}
          label={'Email Address'}
          name={'email'}
          autoFocus
          value={email}
          disabled={loading || user !== null}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => setEmail(event.target.value)}
        />
        <TextField
          variant={'outlined'}
          color={'secondary'}
          margin={'normal'}
          required
          fullWidth
          name={'password'}
          label={'Password'}
          type={'password'}
          id={'password'}
          autoComplete={'current-password'}
          value={password}
          disabled={loading || user !== null}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => setPassword(event.target.value)}
        />
        <div className={'login-meta-actions'}>
          <Link href={'/resetting/request'}>Forgotten password</Link>
          <Link href={'/auth/registration'}>Register</Link>
        </div>
        <StyledActionArea>
          <LoaderButtonTextual
            color={'secondary'}
            loading={loading}
            success={Boolean(user)}
            disabled={loading}
            type={'submit'}
            text={{ default: 'Log in', loading: 'Logging in...', success: 'Logged in' }}
          />
        </StyledActionArea>
      </StyledLoginForm>
      <SnackbarContainer />
    </StyledLoginPage>
  )
}

export default LoginForm
